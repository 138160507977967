import React from "react";
import "./Contact.scss"
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
} from '@material-ui/pickers';
import nlLocale from "date-fns/locale/nl";

const API_PATH = '/api/contact/index.php';

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            email: '',
            message: '',
            phone: '',
            formType: 'reserveren',
            arrivalDateTime: '',
            pickupDateTime: new Date(),
            pickupAddress: '',
            deliveryAddress: '',
            arrivalOrPickupTime: 'ophaaltijd',
            error: null
        };
    }

    render() {
        console.log(this.state);
        return (
            <React.Fragment>
                <CssBaseline/>
                <div className="page">
                    <h1>Uw verzoek</h1>
                    <form action="#">
                        <div className="form-container">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Wilt u direct reserveren of een offerte?</FormLabel>
                                <RadioGroup aria-label="gender" name="formType" value={this.state.formType}
                                            onChange={e => this.handleChange(e)}>
                                    <FormControlLabel value="reserveren" control={<Radio/>} label="Reserveren"/>
                                    <FormControlLabel value="offerte" control={<Radio/>} label="Offerte aanvragen"/>
                                </RadioGroup>
                            </FormControl>
                            {this.state.formType && <div>
                                <div>
                                    <FormLabel component="legend">Waar wilt u heen?</FormLabel>
                                    <div>
                                        <TextField
                                            id="pickupAddress"
                                            fullWidth
                                            label="Ophaaladres en stad"
                                            margin="normal"
                                            onChange={e => this.handleChange(e)}
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            id="deliveryAddress"
                                            fullWidth
                                            label="Bestemming"
                                            margin="normal"
                                            onChange={e => this.handleChange(e)}
                                        />
                                    </div>
                                </div>
                                {this.state.deliveryAddress.length > 3 && <div>
                                    <FormGroup>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Wilt u uw ophaal- of gewenste aankomsttijd
                                                doorgeven?</FormLabel>
                                            <RadioGroup aria-label="gender" name="arrivalOrPickupTime"
                                                        value={this.state.arrivalOrPickupTime}
                                                        onChange={e => this.handleChange(e)}>
                                                <FormControlLabel value="ophaaltijd" control={<Radio/>}
                                                                  label="Ophaaltijd"/>
                                                <FormControlLabel value="aankomsttijd" control={<Radio/>}
                                                                  label="Aankomsttijd"/>

                                            </RadioGroup>
                                            <div className="timepicker-wrapper">
                                                {this.state.arrivalOrPickupTime === "ophaaltijd" &&
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                                                    <DateTimePicker
                                                        format="dd MMMM HH:mm"
                                                        okLabel="OK"
                                                        clearLabel="Opnieuw"
                                                        cancelLabel="Annuleren"
                                                        label="Gewenste ophaaldag en tijd"
                                                        value={this.state.pickupDateTime}
                                                        ampm={false}
                                                        onChange={(e) => {
                                                            this.handleDateChange(e, "pickupDateTime")
                                                        }}

                                                    />
                                                </MuiPickersUtilsProvider>
                                                }
                                                {this.state.arrivalOrPickupTime === "aankomsttijd" &&
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                                                    <DateTimePicker
                                                        format="dd MMMM HH:mm"
                                                        okLabel="OK"
                                                        clearLabel="Opnieuw"
                                                        cancelLabel="Annuleren"
                                                        label="Gewenste aankomstdag en tijd"
                                                        value={this.state.arrivalDateTime}
                                                        ampm={false}
                                                        onChange={(e) => {
                                                            this.handleDateChange(e, "arrivalDateTime")
                                                        }}

                                                    />
                                                </MuiPickersUtilsProvider>
                                                }
                                            </div>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Uw gegevens voor bevestiging</FormLabel>
                                            <div>
                                                <TextField
                                                    id="fname"
                                                    fullWidth
                                                    className="textfield"
                                                    label="Voornaam"
                                                    margin="normal"
                                                    onChange={e => this.handleChange(e)}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    fullWidth
                                                    id="lname"
                                                    className="textfield"
                                                    label="Achternaam"
                                                    margin="normal"
                                                    onChange={e => this.handleChange(e)}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    id="email"
                                                    fullWidth
                                                    className="textfield"
                                                    label="Email adres"
                                                    margin="normal"
                                                    onChange={e => this.handleChange(e)}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    id="phone"
                                                    fullWidth
                                                    className="textfield"
                                                    label="Telefoon of mobiel nummer"
                                                    margin="normal"
                                                    onChange={e => this.handleChange(e)}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    id="message"
                                                    fullWidth
                                                    className="textfield"
                                                    label="Eventuele opmerkingen"
                                                    margin="normal"
                                                    rows={6}
                                                    multiline
                                                    onChange={e => this.handleChange(e)}
                                                />
                                            </div>
                                        </FormControl>
                                    </FormGroup>
                                    <Box mt={3}>
                                        <Button variant="contained"
                                                color="primary"
                                                onClick={e => this.handleSubmit(e)}>
                                            Verzenden
                                        </Button>
                                    </Box>
                                </div>}
                            </div>
                            }
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    handleChange = (event) => {
        this.setState({[event.target.name ? event.target.name : event.target.id]: event.target.value})
    }

    handleDateChange = (date, id) => {
        let correctedDate = this.convertUTCDateToLocalDate(new Date(date));
        let cleanupId = '';
        if (id === 'pickupDateTime') {
            cleanupId = 'arrivalDateTime'
        } else {
            cleanupId = 'pickupDateTime'
        }
        this.setState({[id]: correctedDate, [cleanupId]: ''})
    }

    convertUTCDateToLocalDate(date) {
        let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

        const offset = date.getTimezoneOffset() / 60;
        const hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {'content-type': 'application/json'},
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: result.data.sent
                })
            })
            .catch(error => this.setState({error: error.message}));
    };
}
