import React from "react";
import "./App.scss"
import {
    Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Home from "./routes/home/Home";
import Contact from "./routes/contact/Contact";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import RobotoSlab from 'typeface-roboto-slab';
import history from './history';
import { Mail, WhatsApp } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    titles: {},
    title: {
        display: "inline",
        margin: theme.spacing(2)
    },
    link: {
        fontSize: "1.2rem",
        margin: theme.spacing(2),
        color: "white",
        textDecoration: "none",
    },
}));


const theme = createMuiTheme({
    palette: {
        primary: {main: "#25282F"},
        secondary: {main: "#56A9FC"},
    },
    typography: {
        fontFamily: 'Raleway, Arial',
        h2: {
            fontSize: "2rem",
            fontWeight: "500",
            color: "#0E1F3D"
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [RobotoSlab],
            },
        },
    },
});

export default function BasicExample() {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <div>
                    <AppBar position="static">
                        <div className="header">
                            <div className="links">
                                <Link to="/" className={classes.link}>
                                    <img className="logo" src="/logo.svg" alt=""/>
                                </Link>
                                <Link to="/reserveer" className={classes.link}>
                                    Online reserveren
                                </Link>
                            </div>
                            <div className="call-us">
                                <div>Direct informatie of reserveren</div>
                                <div className="phone">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round">
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
                                    </svg>
                                    <a href="tel:+31618174111">+31 6 81 74 1110</a></div>
                            </div>
                        </div>
                    </AppBar>
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route path="/reserveer">
                            <Contact/>
                        </Route>
                    </Switch>
                    <div className="footer-wrapper">
                        <footer>
                            <Link to="/" className={classes.link}>
                                <img className="logo" src="/logo.svg" alt=""/>
                            </Link>
                            <div className="address">
                                <p><strong>City Taxi Maastricht</strong><br/>
                                    <p>Standplaats: Station Maastricht</p>
                                    <p>Looiwinkelstraat 19<br/>
                                    6176 EA Spaubeek</p>
                                    Kvk: 74557688<br/>
                                    BTW: NL 859947580 B01
                                </p>
                            </div>
                            <div className="contact">
                                <div className="link"><a
                                    href="mailto:info@citytaximaastricht.nl"><Mail/> info@citytaximaastricht.nl</a>
                                </div>
                                <div className="link">
                                    <a href="https://wa.me/31681741110"><WhatsApp/> Stuur WhatsApp bericht</a>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </Router>
        </ThemeProvider>
    );
}
