import React from "react";
import "./Home.scss";
import Fab from "@material-ui/core/Fab";
import history from '../../history';
import { EventAvailable } from "@material-ui/icons";
import TrustPilotWidget from "../../components/TrustPilotWidget/TrustPilotWidget";

export default class Home extends React.Component {

    gotoForm = () => {
        history.push("/reserveer");
    }
    render() {

        return (
            <div>
                <div className="page-wrapper">
                    <div className="banner-wrapper">
                        <div className="banner">
                            <div className="box">
                                <h1>De makkelijkste weg naar uw bestemming</h1>
                                <Fab variant="extended" color="primary" aria-label="reserveer" onClick={() => this.gotoForm()}>
                                    <EventAvailable /> Reserveer een rit
                                </Fab>
                            </div>
                        </div>
                        <div className="car">
                            <img className="body" src="/car.svg" alt=""/>
                            <img className="wheel-front" src="/wheel.svg" alt=""/>
                            <img className="wheel-back" src="/wheel.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className="page-wrapper">
                    <div className="services-wrapper">
                        <div className="services">
                            <div section="section services">
                                <h2>Onze diensten</h2>
                                <p>U kunt bij ons o.a. terecht voor personenvervoer, zakelijk vervoer en vervoer van en naar de volgende luchthavens: Brussel, Charleroi, Eindhoven, Amsterdam Schiphol, Düsseldorf, Frankfurt, Köln, Parijs Charles de Gaulle.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-wrapper">
                    <div className="page items">
                        <div className="section payment">
                            <h4>Naast contante betaling accepteren wij</h4>
                            <div>
                                <img src="/maestro.svg" alt=""/>
                                <img src="/mastercard.svg" alt=""/>
                                <img src="/visa.svg" alt=""/>
                            </div>
                        </div>
                        <div className="section review">
                            <h4>Wat vinden klanten van ons</h4>
                            <div>
                                <TrustPilotWidget></TrustPilotWidget>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
