import React, { Component } from "react";

const TrustBox = ({trustBoxRef}) => (
    <div
        ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
        className="trustpilot-widget" // Renamed this to className.
        data-locale="nl-NL"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="5ddc1665bc595500015a52bc"
        data-theme="light"
    >
        <a href="https://nl.trustpilot.com/review/citytaximaastricht.nl" target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </div>
);

export default class TrustPilotWidget extends Component {
    constructor(props) {
        super(props);
        this.trustBoxRef = React.createRef();
    }

    componentDidMount() {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }

    render() {
        return <TrustBox trustBoxRef={this.trustBoxRef}/>;
    }
}
